import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import userLogo from "./../Assets/user.png";

export default function UserRow({ item }) {
  var style = "mt-1 text-sm truncate ";

  return (
    <li key={item.Id}>
      <a href={"#"} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">
              <img
                src={userLogo}
                className="h-14 w-14 rounded"
                width={95}
                height={95}
              />
              {/*  <img className="h-14 w-14 rounded" src={job.Logo ?? "logo.png"} alt="" /> */}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
              <div>
                <p className="truncate text-sm font-medium text-indigo-600">
                  {item.Name ?? "Undefined"}
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  <span className="mt-1 truncate">
                    {item.Email ?? "Undefined"}
                  </span>
                </p>
                {/* <span className={style}>Date</span> */}
              </div>
              {/* 
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                         
                          </p>
                          <div className="mt-2 flex items-center text-sm text-gray-500">
                          <img  src={agentLogo}  alt="Tasks" className="h-9 w-9 mr-2 rounded"
                            width={20} height={20} />{"Unassigned"}
                          </div>
                        </div>
                      </div>
                      */}
            </div>
          </div>
          <div>
            {/* 
                  <button className="mr-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                      Download
                  </button>
                  */}
          </div>
          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </a>
    </li>
  );
}
