import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";

export default function TableView({ data, rows, columns }) {
  const columnList = columns || Object.getOwnPropertyNames(data);
  const rowList = rows || Object.getOwnPropertyNames(data[columnList[0]]);

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {columnList.map((column) => (
          <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
            {column}
          </span>
        ))}
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnList.map((column) => (
                <TableCell align="left">
                  <b>{column}</b>
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowList.map((row) => (
              <TableRow
                key={row}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columnList.map((column) => (
                  <TableCell width={150} align="left">
                    {data[column][row]}
                  </TableCell>
                ))}

                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
