import { useCallback, useState, useEffect } from "react";

import { GetIdentity } from "./../../../components/Auth/AppState";
import { GetUsers } from "./../Api/UserClient";

export default function useUserList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setData([]);

    const identity = GetIdentity();
    GetUsers(identity).then((response) => {
      console.log(response?.data);

      var items = response?.data ?? [];
      setData(items);
      setLoading(false);
    });
  }, []);

  return { data, setData, loading, error };
}
