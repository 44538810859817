import * as React from "react";

import { useCallback, useState, useEffect } from "react";

import Stack from "@mui/material/Stack";

export default function TagsView({ appKey, platform, tags }) {
  return (
    <div>
      <h4 className="font-medium">
        {appKey}-{platform}
      </h4>
      <Stack direction="row" spacing={1}>
        {tags?.map((column) => (
          <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
            {column}
          </span>
        ))}
      </Stack>
    </div>
  );
}
