import * as React from "react";
import useReportSales from "../../Hooks/useReportSales";
import SalesView from "./Components/SalesView";
import LoadingControl from "../../../../components/Core/LoadingControl/LoadingControl";
import ChartView from "../../Components/ChartView";

export default function ReportSales({ project }) {
  const filters = project ? [project.AppKey] : [];

  const reportSettings = {
    Filters: filters,
    Groups: [
      {
        Name: "Business Products",
        AppKeys: [
          "oshasafety",
          "hazmat",
          "appiconmaker",
          "siteaudit",
          "quickinventory",
        ],
      },
      {
        Name: "Mini Products",
        AppKeys: [
          "mlgsounds",
          "vinesounds",
          "beethoven",
          "bach",
          "mozart",
          "handel",
          "puccini",
        ],
      },
    ],

    Visible: true,
    Products: [
      { AppKey: "appiconmaker", Platform: "web", Price: 0.1 },
      //=========
      { AppKey: "oshasafety", Platform: "ios", Price: 13.99 },
      { AppKey: "oshasafety", Platform: "droid", Price: 13.99 },
      //=========
      { AppKey: "hazmat.spanish", Platform: "ios", Price: 13.99 },
      { AppKey: "hazmat.spanish", Platform: "droid", Price: 9.99 },
      { AppKey: "hazmat", Platform: "ios", Price: 13.99 },
      { AppKey: "hazmat", Platform: "droid", Price: 13.99 },
      //==========
      { AppKey: "mlgsounds", Platform: "ios", Price: 1.99 },
      { AppKey: "vinesounds", Platform: "ios", Price: 1.99 },
      { AppKey: "beethoven", Platform: "ios", Price: 1.99 },
      { AppKey: "bach", Platform: "ios", Price: 1.99 },
      { AppKey: "mozart", Platform: "ios", Price: 1.99 },
      { AppKey: "handel", Platform: "ios", Price: 1.99 },
      { AppKey: "puccini", Platform: "ios", Price: 1.99 },
      //========
      { AppKey: "quickinventory", Platform: "ios", Price: 9.99 },
      { AppKey: "siteaudit", Platform: "ios", Price: 9.99 },
      { AppKey: "siteaudit", Platform: "droid", Price: 9.99 },
      { AppKey: "spamusicboard", Platform: "ios", Price: 1.99 },
      //========
      { AppKey: "hourstracker", Platform: "ios", Price: 1.99 }, // TODO: differet subs
    ],
  };

  const { data, loading } = useReportSales();

  var getTotal = (row, columns) => {
    var totalSoldQuantity = 0;

    columns
      .filter((d) => data[d][row] !== 0)

      .map((appKey) => {
        var soldQuantity = data[appKey][row];
        totalSoldQuantity += soldQuantity;
        return soldQuantity;
      });

    return totalSoldQuantity;
  };

  // ---

  var columns = Object.getOwnPropertyNames(data).filter(
    (col) => !col.includes("year") && !col.includes("week")
  );

  var weeks = data && data["week"];
  var chartData =
    weeks &&
    Object.getOwnPropertyNames(weeks).map((m, index) => ({
      week: data["week"][index],
      sales: getTotal(index, columns),
    }));
  chartData && chartData.reverse();

  // ---
  return (
    <div className="text-base text-left">
      {loading ? (
        <LoadingControl />
      ) : (
        <div className="m-3">
          {/* <SalesEditor data={data} /> 
          {/* <TableView data={data} /> */}
          <ChartView data={chartData} />
          <SalesView data={data} settings={reportSettings} />
        </div>
      )}
    </div>
  );
}
