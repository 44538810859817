import { useMatch, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import useProjectGet from "./hooks/useProjectGet";

import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";
import InsightList from "../InsightApp/InsightList";
import FunnelList from "./components/FunnelList";

export default function Dashboard({ project }) {
  //const match = useMatch("/project/:projectId/*");
  //var projectId = match && match.params && match.params.projectId;
  //const { project, loading } = useProjectGet(projectId);

  return (
    <div className="m-5 w-full">
      <div>
        <FunnelList project={project} />
        <InsightList project={project} />
      </div>
    </div>
  );
}
