import * as React from "react";
import { styled } from "@mui/material/styles";
import { Fragment, useState } from "react";

import iteration_logo from "../../Assets/Images/iteration_icon.png"; //'/../Assets/Images/project_icon.png';
import ItemPopup from "./ItemPopup";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const statusList = [
  { Name: "NotStarted", Value: "not-started", Color: "white" },
  { Name: "Active", Value: "active", Color: "white" },
  { Name: "Pending", Value: "pending", Color: "#ffffe6" },
  { Name: "Closed", Value: "closed", Color: "#ecf9ec" },
];

export default function ItemView({ meta, updateList }) {
  const url = "/project/" + meta.ProjectId + "/iterations/" + meta.Id;
  const urlEdit =
    "/project/" + meta.ProjectId + "/iterations/" + meta.Id + "/edit";

  const foundStatus = statusList.filter((f) => f.Value === meta.Status)[0];
  const logo =
    meta.Project && meta.Project?.Logo ? meta.Project.Logo : iteration_logo;

  const [open, setOpen] = useState(false);

  return (
    <>
      <a maxWidth href={url}>
        <li
          style={{ backgroundColor: foundStatus?.Color }}
          class="flex flex-row"
        >
          <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
            <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
              <img
                alt="profil"
                src={logo}
                class="mx-auto object-cover rounded-2 h-10 w-10"
              />
            </div>

            <div class="flex-auto pl-1">
              <div class="font-medium dark:text-white text-sm">{meta.Name}</div>
              <div class="text-gray-600 dark:text-gray-200 text-sm">
                {foundStatus?.Name}
              </div>
            </div>

            {/* 
  <div class="flex-1 pl-1">
    <div class="text-sm">Project</div>
    <div class="text-gray-600 dark:text-gray-200 text-sm">{"Project ?"}</div>
  </div>
  */}

            <div class="flex-none mx-3">
              <div class="text-sm">Feature</div>
              <div class="text-gray-600 dark:text-gray-200 text-sm">
                Sales Conversion
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm">Settings</div>
              <div class="text-gray-600 dark:text-gray-200 text-sm">
                <a href="#" onClick={() => setOpen(true)}>
                  Edit
                </a>
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm">Deadline</div>
              <div class="text-gray-600 dark:text-gray-200 text-sm">
                {new Date(meta.Deadline).toISOString().split("T")[0]}
              </div>
            </div>

            <div class="flex-none mx-3">
              <div className="text-sm">
                {meta.Revenue > meta.Expense ? "Revenue" : "Expense"}
              </div>
              <div
                className={
                  meta.Revenue > meta.Expense
                    ? "text-green-600 text-sm font-semibold"
                    : "text-red-600 text-sm font-semibold"
                }
              >
                {meta.Revenue > meta.Expense ? meta.Revenue : meta.Expense}
              </div>
            </div>
          </div>
        </li>
      </a>

      <ItemPopup
        open={open}
        setOpen={setOpen}
        itemMeta={meta}
        updateList={updateList}
      />
    </>
  );
}
