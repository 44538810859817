import axios from "axios";

export function GetList() {
  const apiPayload = {};
  return Exec("paywall/all", apiPayload);
}

export function GetOne(guideId) {
  const apiPayload = { Id: guideId };
  return Exec("paywall/one", apiPayload);
}

export function Edit(guide) {
  const apiPayload = { Model: guide };
  return Exec("paywall/edit", apiPayload);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  const serverUrl =
    "https://jexdy7u3gs7dzufficyeo4ob5y0trgqh.lambda-url.us-west-2.on.aws/";
  return axios.post(serverUrl, requestBody);
}
