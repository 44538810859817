import * as React from "react";

import ItemView from "./components/ItemView";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../configurations/config";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";

import LoadingControl from "../../../components/Core/LoadingControl/LoadingControl";
import moment from "moment";
import { GetTimeFrames } from "./tools/DateUtils";

export default function IterationMetaList() {
  const location = useLocation();
  var template =
    location.pathname.indexOf("project/") > 0
      ? "/project/:projectId/:appId"
      : "/iterations";
  const match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;

  //=======
  const [status, setStatus] = useState("-1");
  const [text, setText] = useState("");
  //=======
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  //=======

  const updateList = (meta) => {
    console.log("update list");

    //setLoading(true);
    const newList = data.map((item) => {
      if (item.Id === meta.Id) {
        return meta;
      }
      return item;
    });
    setData(newList);
    //setLoading(false);
  };

  useEffect(() => {
    const loadAsyncStuff = async () => {
      setLoading(true);
      const requestBody = {
        Action: "iterations/all",
        Payload: {
          ProjectId: projectId,
        },
      };
      const response = await axios.post(Config.serverUrl, requestBody);

      const getInt = (iter) => {
        if (iter.Status === "closed") return 4;
        if (iter.Status === "pending") return 3;
        return 1;
      };
      const list = response.data.sort((a, b) => {
        return getInt(a) - getInt(b);
      });

      setData(list);
      setLoading(false);
    };
    loadAsyncStuff();
  }, [projectId]);

  var timeout = null;
  const handleOnChange = (e) => {
    const searchValue = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setText(searchValue);
      console.log(searchValue);
      timeout = null;
    }, 500);
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setStatus(e.target.value);
  };

  var timeFrames = GetTimeFrames();
  var displayData = (timeFrame) => {
    return data
      .filter(
        (p) =>
          moment(p.Deadline) >= timeFrame.StartDate &&
          moment(p.Deadline) <= timeFrame.EndDate
      )
      .filter((p) => (status === "-1" ? true : p.Status.toString() === status))
      .filter((p) =>
        text.length > 0
          ? p.Name?.toLowerCase().includes(text.toLowerCase())
          : true
      )
      .sort((a, b) => a.Status - b.Status);
  };

  return (
    <div className="m-5 w-full">
      <a
        type="button"
        href={`/project/${projectId}/iterations/create/`}
        class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        Create
      </a>

      <div>
        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <div className="flex my-5">
              <input
                type="text"
                id="search"
                name="search"
                onChange={handleOnChange}
                className="mr-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
              />

              <select
                className="w-40 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id="status"
                name="status"
                defaultValue={status}
                onChange={handleChange}
              >
                <option value={-1}>All</option>
                <option value={0}>NotStarted</option>
                <option value={1}>Active</option>
                <option value={2}>Pending</option>
                <option value={3}>Closed</option>
              </select>
            </div>

            <div>
              <div class="group-open:animate-fadeIn">
                <ul class="flex flex-col divide-y w-full ">
                  {data.map((iter) => (
                    <ItemView
                      meta={iter}
                      key={iter.Id}
                      updateList={updateList}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
