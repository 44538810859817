import { useCallback, useState, useMatch, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Config from "../../../../configurations/config";
import defaultState from "../store/DefaultState";

export default function useIterationGet(iterationID, projectID) {
  const [data, setData] = useState(defaultState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    // POST request using axios with async/await
    const requestBody = {
      Action: "iterations/get",
      Payload: {
        Id: iterationID,
      },
    };

    axios.post(Config.serverUrl, requestBody).then((response) => {
      console.log(response.data);
      if (response && response.data) {
        console.log(response.data);
        var newData = response.data;

        setData(newData);
      }
      setLoading(false);
    });
  }, [iterationID]);

  return { data, setData, loading };
}
