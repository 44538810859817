import * as React from "react";

import DottedSpinner from "../Core/DottedSpinner/DottedSpinner";

import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";

import { useParams, useMatch, useNavigate } from "react-router-dom";
import useProjectGet from "./Hooks/useProjectGet";
import TagView from "../Core/TagView/TagView";
import LogoView from "./Components/LogoView";
import { GetProject, EditProject } from "./Api/ProjectClient";

import toast from "react-hot-toast";
import LoadingControl from "../Core/LoadingControl/LoadingControl";
import UsersView from "./Components/UsersView/UsersView";

export default function ProjectEdit() {
  const navigate = useNavigate();
  const match = useMatch("/project/:projectId/*");
  var projectId = match && match.params && match.params.projectId;
  const { project, setProject, loading } = useProjectGet(projectId, true);

  console.log(projectId);

  const setUsers = (users) => {
    setProject({
      ...project,
      Users: users,
    });
  };

  const setTags = (tags) => {
    setProject({
      ...project,
      Tags: tags,
    });
  };

  const [saving, setSaving] = useState(false);

  const handleOnBlur = (e) => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();

    if (elementId === "name") setProject({ ...project, Name: attributeValue });

    if (elementId === "appkey")
      setProject({ ...project, AppKey: attributeValue });

    if (elementId === "sales")
      setProject({ ...project, Sales: attributeValue });

    if (elementId === "alert")
      setProject({ ...project, Alert: attributeValue });

    if (elementName === "status")
      setProject({ ...project, Status: attributeValue });
  };

  const handleLogoUpdate = (url) => {
    setSaving(true);
    setProject({ ...project, Logo: url });
    setSaving(false);
    toast.success("Logo updated");
  };

  const handleSubmit = (event) => {
    setSaving(true);

    console.log(project);
    const response = EditProject(project).then((response) => {
      //var data = response.data;
      //var newProject = { ...project, Id: data.Data["id"] };
      //setProject(newProject);
      setSaving(false);
      toast.success("Saved");
    });
  };

  const handleDelete = () => {
    var result = window.confirm("Please confirm to delete.");
    if (!result) return;

    var projectEx = { ...project, RecordState: 1 };
    setSaving(true);
    const response = EditProject(projectEx).then((response) => {
      setSaving(false);
      navigate("/projects");
    });
  };

  return (
    <div className="m-5" style={{ width: "500px" }}>
      {!project ? (
        <LoadingControl />
      ) : (
        <div spacing={3}>
          <div style={{ marginBottom: "50px" }}>
            <LogoView project={project} update={handleLogoUpdate} />
          </div>

          <div class="mb-6">
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Project Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onBlur={handleOnBlur}
              defaultValue={project && project.Name}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Project Name"
              required
            />
          </div>

          <div class="mb-6">
            <label
              for="appkey"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              App Key
            </label>
            <input
              type="text"
              id="appkey"
              name="appkey"
              onBlur={handleOnBlur}
              defaultValue={project && project.AppKey}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="App Key"
              required
            />
          </div>

          <div class="mb-6">
            <label
              for="status"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Status
            </label>
            <select
              id="status"
              name="status"
              defaultValue={project && project.Status}
              onBlur={handleOnBlur}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="0">Open</option>
              <option value="1">Closed</option>
            </select>
          </div>

          <div class="mb-6">
            <label
              for="sales"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Sales
            </label>
            <input
              type="text"
              id="sales"
              name="sales"
              onBlur={handleOnBlur}
              defaultValue={project && project.Sales}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Sales"
              required
            />
          </div>

          <div class="mb-6">
            <label
              for="alert"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Alert
            </label>
            <select
              id="alert"
              name="alert"
              defaultValue={project && project.Alert}
              onBlur={handleOnBlur}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>

          <div class="mb-6">
            <label
              for="tags"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Tags
            </label>
            <TagView tags={project.Tags} setTags={setTags} />
          </div>

          <div class="mb-6">
            <label
              for="tags"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Users
            </label>
            <UsersView users={project.Users} setUsers={setUsers} />
          </div>

          <div className="flex">
            <div className="m-2">
              <button
                onClick={handleSubmit}
                fullWidth
                type="submit"
                className="fullWidth bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              >
                {saving ? <DottedSpinner /> : <>Save</>}
              </button>
            </div>

            <div className="m-2">
              <button
                onClick={handleDelete}
                fullWidth
                className="fullWidth bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
