import * as React from "react";

import useFunnelData from "../Hooks/useFunnelData";

export default function FunnelTable({ meta }) {
  const { data } = useFunnelData(meta?.AppKey, meta?.Platform);

  var originalColumns = Object.getOwnPropertyNames(data);

  var metrics = [];
  if (meta && meta.Metrics && meta.Metrics.length > 0) {
    metrics = meta.Metrics.filter(
      (d) =>
        d.RecordState !== 1 /*&& originalColumns.some((c) => c === d.Name)*/
    );
  }

  var keyMetric = meta?.KeyMetric;

  var rules = [];
  if (meta && meta.Rules && meta.Rules.length > 0) rules = meta.Rules;

  var rows = [];
  if (metrics.length > 0) rows = Object.getOwnPropertyNames(originalColumns[0]);

  const GetConversion = (row, column) => {
    // #
    var currColIndex = metrics.indexOf(column);
    var nextColumn = metrics.at(currColIndex + 1).Name;
    var previousColumn = metrics.at(currColIndex - 1).Name;
    // #
    var nextValue = data.hasOwnProperty(nextColumn) ? data[nextColumn][row] : 0;
    var previousValue = data.hasOwnProperty(previousColumn)
      ? data[previousColumn][row]
      : 0;
    // #

    if (!nextValue || !previousValue) return 0;

    console.log(previousValue + "-" + currColIndex + "-" + nextValue);
    var conversion = nextValue / (previousValue / 100);
    // #
    return conversion.toFixed(2);
  };

  return (
    <div>
      <table className="table-fixed">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="font-normal">
            {metrics.map((metric) => (
              <th className="text-center">{metric.Name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.slice(0, 4).map((row) => (
            <tr
              key={row}
              className="border-b transition duration-300 ease-in-out hover:bg-neutral-100"
            >
              {metrics.map((metric) => {
                var cellValue = Object.hasOwn(data, metric.Name)
                  ? data[metric.Name][row]
                  : 0;
                if (metric.Name.includes("%"))
                  cellValue = GetConversion(row, metric);

                var foundRule = rules.find((x) => x.Metric === metric.Name);

                var isKeyMetric = metric.Name === keyMetric;
                var cellFailed =
                  foundRule && parseInt(foundRule.Value) > parseInt(cellValue);

                var baseStyle = isKeyMetric
                  ? "text-center bg-green-200"
                  : "text-center";

                var cellStyle = cellFailed
                  ? "text-center bg-red-200"
                  : baseStyle;

                return <td className={cellStyle}>{cellValue}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
