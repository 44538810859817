import { useMatch, useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";

import { EditGuide } from "./Api/HelpGuideClient";
import useGuideGet from "./Hooks/useGuideGet";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";
import EditableList from "./Components/EditableList";

export default function GuideEdit({ isNew }) {
  const location = useLocation();
  const navigate = useNavigate();

  const match = useMatch("/helpguide/:guideId");
  var guideId = match && match.params && match.params.guideId;
  //======

  const { guide, setGuide, loading } = useGuideGet(isNew ? null : guideId);
  const [saving, setSaving] = useState(false);
  // =====

  //=========
  const handleOnBlur = (e) => {
    let elementId = e.target.id;
    let elementName = e.target.name;

    let attributeValue = e.target.value.toString().trim();
    if (elementId === "text") setGuide({ ...guide, text: attributeValue });

    if (elementName === "archived")
      setGuide({ ...guide, archived: attributeValue });
    //if (elementId === "appkey")
    //  setGuide({ ...guide, AppKey: attributeValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    const response = EditGuide(guide).then((response) => {
      console.log(response.data);
      setSaving(false);
      navigate("/helpguide");
    });
  };

  return (
    <div className="m-5 w-96">
      {loading ? (
        <>
          <LoadingControl />
        </>
      ) : (
        <>
          <div component="form">
            {/* 

            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Created At
            </label>
            <div>{guide.CreatedAt}</div>
            */}

            <label class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white">
              Text
            </label>
            <input
              id="text"
              name="text"
              onBlur={handleOnBlur}
              defaultValue={guide && guide.text}
              class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />

            {/* 

            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              AppKey
            </label>
            <input
              id="appkey"
              name="appkey"
              onBlur={handleOnBlur}
              defaultValue={guide && guide.AppKey}
              class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            */}

            <label
              for="archived"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Archived
            </label>
            <select
              id="archived"
              name="archived"
              defaultValue={guide && guide.archived}
              onBlur={handleOnBlur}
              label="Archived"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>

            <EditableList
              items={guide?.items ?? []}
              setItems={(items) => {
                setGuide({ ...guide, items: items });
              }}
            />

            <button
              onClick={handleSubmit}
              class=" block w-full my-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              {saving ? <>Saving...</> : <>Save</>}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
