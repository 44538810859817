import axios from "axios";

export function GetGuideList() {
  const apiPayload = {};
  return Exec("helpguide/all", apiPayload);
}

export function GetGuideOne(guideId) {
  const apiPayload = { Id: guideId };
  return Exec("helpguide/get", apiPayload);
}

export function EditGuide(guide) {
  const apiPayload = { Model: guide };
  return Exec("helpguide/edit", apiPayload);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));

  const serverUrl =
    "https://pekh32fwsawrtsprn4tnhzn6ei0vcdll.lambda-url.us-west-2.on.aws/";
  return axios.post(serverUrl, requestBody);
}
