import { useMatch, useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";

import { Edit } from "./Api/PayWallClient";
import usePayWallOne from "./Hooks/usePayWallOne";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";
import ButtonEditor from "./Components/ButtonEditor";
import ProductListEditor from "./Components/ProductListEditor";
import HeroSectionEditor from "./Components/HeroSectionEditor";
import PlatformProductEditor from "./Components/PlatformProductEditor";

export default function PayWallEdit({ isNew }) {
  const location = useLocation();
  const navigate = useNavigate();

  const match = useMatch("/paywalls/:payWallId");
  var payWallId = match && match.params && match.params.payWallId;
  //======

  const { payWall, setPayWall, loading } = usePayWallOne(
    isNew ? null : payWallId
  );
  const [saving, setSaving] = useState(false);

  // =====

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    const response = Edit(payWall).then((response) => {
      console.log(response.data);
      setSaving(false);
      navigate("/paywalls");
    });
  };

  return (
    <div className="m-5">
      {loading ? (
        <LoadingControl />
      ) : (
        <div component="form">
          <label class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white">
            Text
          </label>
          <input
            id="text"
            name="text"
            onBlur={(e) => setPayWall({ ...payWall, text: e.target.value })}
            defaultValue={payWall && payWall.text}
            class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          <label class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white">
            Version
          </label>
          <input
            id="version"
            name="version"
            onBlur={(e) => setPayWall({ ...payWall, version: e.target.value })}
            defaultValue={payWall && payWall.version}
            class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          {/* --- */}
          <HeroSectionEditor
            hero={payWall?.hero ?? { logo: "", text: "", features: [] }}
            setHero={(h) => setPayWall({ ...payWall, hero: h })}
          />

          {/* --- */}
          <ProductListEditor
            products={payWall?.products ?? []}
            setProducts={(h) => setPayWall({ ...payWall, products: h })}
          />

          {/* --- */}
          <ButtonEditor
            button={payWall?.button ?? { text: "", color: "" }}
            setButton={(b) => setPayWall({ ...payWall, button: b })}
          />

          <label
            for="archived"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Archived
          </label>
          <select
            id="archived"
            name="archived"
            defaultValue={payWall && payWall.archived}
            onBlur={(e) => setPayWall({ ...payWall, archived: e.target.value })}
            label="Archived"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>

          <button
            onClick={handleSubmit}
            class=" block w-full my-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            {saving ? <>Saving...</> : <>Save</>}
          </button>
        </div>
      )}
    </div>
  );
}
