import { useForm } from "react-hook-form";
import useUserEdit from "./Hooks/useUserEdit";

import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "../../components/Core/LoadingControl/LoadingControl";

export default function UserEdit() {
  const form = useForm();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { userEdit, loading } = useUserEdit(formValues);

  return (
    <div className="m-5">
      <form onSubmit={handleSubmit(userEdit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              New Team Member
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    {...register("name", { required: "Name is required" })}
                    id="name"
                    placeholder="John"
                    className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    placeholder="john@company.com"
                    id="email"
                    name="email"
                    autocomplete="new-email"
                    type="email"
                    {...register("email", { required: "Email is required" })}
                    className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    placeholder="◉◉◉◉◉◉"
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    id="password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6">
          <a
            href="/users"
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </a>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loading ? <Spinner /> : <>Save</>}
          </button>
        </div>
      </form>
    </div>
  );
}
