import React, { useState } from "react";
import PlatformProductEditor from "./PlatformProductEditor";
import ButtonEditor from "./ButtonEditor";

const ProductListEditor = ({ products, setProducts }) => {
  /*
  const [products, setProducts] = useState([
    {
      name: "Product 1",
      permissionGroup: "",
      text: "",
      selected: false,
      platforms: [
        {
          sku: "123",
          price: 1.22,
          platform: "ios",
          type: "inapp",
          offers: [{ text: "Offer 1", description: "Description 1" }],
        },
      ],
    },
  ]);
  */

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][field] = value;
    setProducts(updatedProducts);
  };

  const addProduct = () => {
    setProducts([
      ...products,
      {
        name: "new_product",
        permissionGroup: "",
        text: "",
        price: "",
        selected: false,
        trial: false,
        platforms: [],
      },
    ]);
  };

  const addPlatform = (productIndex) => {
    const updatedProducts = [...products];
    updatedProducts[productIndex].platforms.push({
      sku: "",
      price: "9.99 per month",
      originalPrice: "9.99",
      defaultPrice: "9.99",
      platform: "",
      type: "",
      offers: [],
    });
    setProducts(updatedProducts);
  };

  const deleteProduct = (index) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Product List</h1>

      {products.map((product, productIndex) => (
        <div
          key={productIndex}
          className="border p-4 mb-4 rounded-lg bg-gray-50 shadow-sm"
        >
          <div className="mb-4">
            <input
              type="text"
              value={product.name}
              onChange={(e) =>
                handleProductChange(productIndex, "name", e.target.value)
              }
              placeholder="Name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 mb-2"
            />
            <input
              type="text"
              value={product.permissionGroup}
              onChange={(e) =>
                handleProductChange(
                  productIndex,
                  "permissionGroup",
                  e.target.value
                )
              }
              placeholder="Permission Group"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 mb-2"
            />

            <input
              type="text"
              value={product.price}
              onChange={(e) =>
                handleProductChange(productIndex, "price", e.target.value)
              }
              placeholder="Price"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 mb-2"
            />

            <input
              type="text"
              value={product.text}
              onChange={(e) =>
                handleProductChange(productIndex, "text", e.target.value)
              }
              placeholder="Text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={product.selected}
                onChange={(e) =>
                  handleProductChange(
                    productIndex,
                    "selected",
                    e.target.checked
                  )
                }
                className="form-checkbox h-5 w-5 text-green-600"
              />
              <span className="ml-2 text-gray-700">Selected</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={product.trial}
                onChange={(e) =>
                  handleProductChange(productIndex, "trial", e.target.checked)
                }
                className="form-checkbox h-5 w-5 text-green-600"
              />
              <span className="ml-2 text-gray-700">Trial</span>
            </label>

            <button
              onClick={() => deleteProduct(productIndex)}
              className="ml-4 text-red-500 font-medium hover:underline"
            >
              Delete
            </button>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Platforms
            </h2>

            {product.platforms.map((platform, platformIndex) => (
              <div
                key={platformIndex}
                className="border p-2 mb-2 rounded-md bg-white shadow-sm"
              >
                <PlatformProductEditor
                  product={platform}
                  setProduct={(p) => {
                    const updatedProducts = [...products];
                    updatedProducts[productIndex].platforms[platformIndex] = p;
                    setProducts(updatedProducts);
                  }}
                  removeProduct={() => {
                    const updatedProducts = [...products];
                    updatedProducts[productIndex].platforms.splice(
                      platformIndex,
                      1
                    );
                    setProducts(updatedProducts);
                  }}
                />
              </div>
            ))}

            <button
              onClick={() => addPlatform(productIndex)}
              className="mt-2 text-blue-500 font-medium hover:underline"
            >
              Add Platform
            </button>
          </div>

          <div>
            <ButtonEditor
              button={product?.button ?? { text: "", color: "" }}
              setButton={(b) => {
                const updatedProducts = [...products];
                updatedProducts[productIndex]["button"] = b;
                setProducts(updatedProducts);
              }}
            />
          </div>
        </div>
      ))}

      <button
        onClick={addProduct}
        className="mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow hover:bg-green-600"
      >
        Add Product
      </button>
    </div>
  );
};

export default ProductListEditor;
