import React, { useEffect } from "react";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import ProjectEdit from "../Project/ProjectEdit";

import IterationMetaList from "../../apps/IterationApp/IterationMeta/IterationMetaList";
import IterationMetaEdit from "../../apps/IterationApp/IterationMeta/IterationMetaEdit";
import Iteration from "../../apps/IterationApp/Iteration/Iteration";
//==========
import AppStoreEdit from "../../apps/StoreAnalyticsApp/AppStoreEdit";
import AppStoreList from "../../apps/StoreAnalyticsApp/AppStoreList";
//==========
import ExperimentEdit from "../../apps/ExperimentApp/ExperimentEdit";
import ExperimentList from "../../apps/ExperimentApp/ExperimentList";
import Experiment from "../../apps/ExperimentApp/Experiment";
//==========
import FunnelList from "../../apps/FunnelApp/FunnelList";
import Funnel from "../../apps/FunnelApp/Funnel";
//==========
import StoreListingEdit from "../../apps/StoreListingApp/StoreListingEdit";
//==========
import Dashboard from "../../apps/DashboardApp/Dashboard";
import TicketList from "../../apps/HelpDesk/TicketList";
import Sidebar from "../Core/Sidebar/Sidebar";
import Storage from "../../apps/StorageApp/Storage";
import InsightList from "../../apps/InsightApp/InsightList";
import useProjectGet from "./Hooks/useProjectGet";
//==========
import FormList from "../../apps/FormApp/FormList";
import Form from "../../apps/FormApp/Form";
import ReportList from "../../apps/ReportApp/ReportList";
//==========

import ReportSales from "../../apps/ReportApp/Reports/SalesReport/ReportSales";

function Project(props) {
  const {
    params: { projectId },
  } = useMatch("/project/:projectId/*");

  const { project } = useProjectGet(projectId);

  const xItems = ["settings", "dashboard", "back"];
  var menuItems = [
    {
      name: "Insights",
      key: "insights",
      url: `/project/${projectId}/insights`,
    },
    {
      name: "######",
      key: "seperator",
      url: "#",
    },
    {
      name: "Dashboard",
      key: "dashboard",
      url: `/project/${projectId}/dashboard`,
    },

    {
      name: "Features",
      key: "funnels",
      url: `/project/${projectId}/funnels`,
    },

    {
      name: "Experiments",
      key: "iterations",
      url: `/project/${projectId}/iterations`,
    },

    {
      name: "HelpDesk",
      key: "helpdesk",
      url: `/project/${projectId}/helpdesk`,
    },

    {
      name: "Storage",
      key: "storage",
      url: `/project/${projectId}/storage/root`,
    },

    {
      name: "Reports",
      key: "reports",
      url: `/project/${projectId}/reports`,
    },

    {
      name: "######",
      key: "seperator",
      url: `#`,
    },
    {
      name: "Settings",
      key: "settings",
      url: `/project/${projectId}/settings`,
    },
    {
      name: "Experiments(Temp)",
      key: "experiments",
      url: `/project/${projectId}/experiments`,
    },
    { name: "Back", key: "back", url: "/projects" },
    //{ name: "Forms", key: "forms", url: `/project/${projectId}/forms` },
  ].filter(
    (item) =>
      true ||
      xItems.includes(item.key) /*||  project?.Apps?.includes(item.key)*/
  );

  /*useEffect(() => {
    if (!atLogin && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atLogin, currentUser]);*/

  return (
    <div class="flex min-h-screen flex-row bg-gray-100 text-gray-800">
      <Sidebar type={"project"} project={project} menuItems={menuItems} />

      <main class="main -ml-64 flex flex-grow flex-col p-4 transition-all duration-150 ease-in md:ml-0">
        <div class="flex h-full w-full bg-white shadow-md">
          {project ? (
            <>
              <Routes className="h-100">
                <Route
                  path=":projectID/iterations/"
                  element={<IterationMetaList />}
                />

                <Route
                  path=":projectID/iterations/create"
                  element={<IterationMetaEdit project={project} />}
                />
                <Route
                  path=":projectID/iterations/:iterationID"
                  element={<Iteration />}
                />
                <Route
                  path=":projectID/iterations/:iterationID/edit"
                  element={<IterationMetaEdit project={project} />}
                />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route path=":projectID/funnels" element={<FunnelList />} />
                <Route path=":projectID/funnels/create" element={<Funnel />} />
                <Route
                  path=":projectID/funnel/:funnelId"
                  element={<Funnel />}
                />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route path=":projectID/helpdesk" element={<TicketList />} />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route
                  path=":projectID/stores/create"
                  element={<AppStoreEdit />}
                />
                <Route path=":projectID/stores" element={<AppStoreList />} />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

                <Route
                  path=":projectID/experiments"
                  element={<ExperimentList />}
                />
                <Route
                  path=":projectID/experiment/create"
                  element={<ExperimentEdit />}
                />
                <Route
                  path=":projectID/experiment/:experimentId"
                  element={<Experiment />}
                />
                <Route
                  path=":projectID/experiment/:experimentId/edit"
                  element={<ExperimentEdit />}
                />

                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

                <Route
                  path=":projectID/insights"
                  element={<InsightList project={project} />}
                />

                <Route path=":projectID/models" element={<>Models</>} />
                <Route path=":projectID/analytics" element={<>Analytics</>} />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route path=":projectID/settings" element={<ProjectEdit />} />
                <Route
                  path=":projectID/listing"
                  element={<StoreListingEdit />}
                />
                <Route
                  path=":projectID/dashboard"
                  element={<Dashboard project={project} />}
                />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route
                  path=":projectID"
                  element={<Dashboard project={project} />}
                />
                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
                <Route
                  path=":projectID/storage/:parentId"
                  element={<Storage storageId={project?.StorageId} />}
                />

                <Route
                  path=":projectID/reports"
                  element={<ReportList project={project} />}
                />
                <Route
                  path=":projectID/reports/sales"
                  element={<ReportSales project={project} />}
                />

                {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

                <Route
                  path=":projectID/forms"
                  element={<FormList project={project} />}
                />
                <Route
                  path=":projectID/forms/:formId"
                  element={<Form project={project} />}
                />
              </Routes>
            </>
          ) : (
            <></>
          )}
        </div>
      </main>
    </div>
  );
}

export default Project;
