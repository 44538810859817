import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetFunnels } from "../Api/FunnelClient";

export default function useFunnelList(projectId) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    projectId &&
      GetFunnels(projectId).then((response) => {
        setList(response.data);
        console.log(response.data);
        setLoading(false);
      });
  }, [projectId]);

  return { list, loading, error };
}
