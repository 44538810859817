import * as React from "react";

import { useCallback, useState, useEffect, useMatch } from "react";
import Button from "@mui/material/Button";

import TaskView from "./TaskView";
import toast from "react-hot-toast";

import IterationStore from "./store/IterationStore";
import { useParams } from "react-router-dom";

import useIterationGet from "./hooks/useIterationGet";
import useIterationEdit from "./hooks/useIterationEdit";

import DottedSpinner from "../../../components/Core/DottedSpinner/DottedSpinner";

import { ExportMeta } from "../Api/IterationClient";
import LoadingControl from "../../../components/Core/LoadingControl/LoadingControl";

import FunnelSelectorView from "./components/FunnelSelectorView";

export default function Iteration() {
  // ===
  const { iterationID, projectID } = useParams();
  const { data, setData, loading } = useIterationGet(iterationID, projectID);

  let store = new IterationStore(data, setData);
  const { iterationEdit, loadingEdit } = useIterationEdit(
    data,
    projectID,
    iterationID
  );

  // ===
  const Export = () => {
    ExportMeta(iterationID).then((result) => {
      var binary_string = window.atob(result.data.Data);
      var bytes = new Uint8Array(binary_string.length);
      for (var i = 0; i < binary_string.length; i++)
        bytes[i] = binary_string.charCodeAt(i);
      var blob = new Blob([bytes], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      window.open(url);
    });
  };

  return (
    <div className="m-5 w-full">
      <div class="space-y-1">
        {loading ? (
          <LoadingControl />
        ) : (
          <>
            <button
              type="button"
              onClick={(e) => iterationEdit()}
              class="text-white mr-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              {loadingEdit ? <DottedSpinner /> : <>Save</>}
            </button>

            <button
              type="button"
              onClick={(e) => Export()}
              class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Export
            </button>

            <div className="mt-10">
              <FunnelSelectorView
                projectId={projectID}
                funnelId={data.FunnelId}
                store={store}
              />
            </div>
            {/* ******** */}
            <label
              htmlFor="funnel"
              className="block text-sm/6 font-medium text-gray-900"
            >
              Tasks
            </label>
            {data?.Tasks?.filter((t) => t.RecordState === 0).map((task) => (
              <TaskView key={task.Id} task={task} store={store} />
            ))}

            <Button
              onClick={(e) => store.AddItem()}
              style={{ backgroundColor: "#21b6ae" }}
              variant="contained"
            >
              + Line Item
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
