import React from "react";

const ButtonEditor = ({ button, setButton }) => {
  const updateButtonText = (e) => {
    setButton({ ...button, text: e.target.value });
  };

  const updateButtonColor = (e) => {
    setButton({ ...button, color: e.target.value });
  };

  return (
    <div className="p-4 bg-gray-100">
      <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-4">
        {/* Editor Section */}
        <div className="flex items-center space-x-2 mb-4">
          {/* Button Text Input */}
          <div className="flex-1">
            <label
              className="block text-xs font-medium mb-1"
              htmlFor="button-text"
            >
              Button Text
            </label>
            <input
              type="text"
              id="button-text"
              value={button.text}
              onChange={updateButtonText}
              className="w-full p-1.5 border border-gray-300 rounded-md text-sm"
              placeholder="Enter button text"
            />
          </div>

          {/* Button Color Input */}
          <div>
            <label
              className="block text-xs font-medium mb-1"
              htmlFor="button-color"
            >
              Button Color
            </label>
            <input
              type="color"
              id="button-color"
              value={button.color}
              onChange={updateButtonColor}
              className="w-12 h-8 border border-gray-300 rounded-md cursor-pointer"
            />
          </div>
        </div>

        {/* Preview Section */}
        <div className="mt-4">
          <h2 className="text-sm font-semibold mb-2">Preview</h2>
          <div className="p-2 border border-gray-300 rounded-md flex justify-center">
            <button
              className="px-4 py-2 font-medium text-white rounded-md shadow-md text-sm"
              style={{ backgroundColor: button.color }}
            >
              {button.text || "Button Preview"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonEditor;
