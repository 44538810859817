import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

export default function useReportList(project) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    var data = [
      {
        Id: "1",
        Name: "Sales Report",
        Url: "/reports/sales",
        tags: ["global"],
      },

      {
        Id: "sales_by_project",
        Name: "Sales Report",
        Url: `/project/${project?.Id ?? "none"}/reports/sales`,
        tags: ["project"],
      },

      {
        Id: "2",
        Name: "Funnels Report",
        Url: "/reports/funnels",
        tags: ["global"],
      },
      {
        Id: "3",
        Name: "Events Weekly",
        Url: "/reports/funnels-weekly",
        tags: ["global"],
      },
      {
        Id: "4",
        Name: "Product Summaries",
        Url: "/reports/product-summaries",
        tags: ["global"],
      },
      {
        Id: "5",
        Name: "Funnels Report v.2",
        Url: "/reports/funnels-v2",
        tags: ["global"],
      },
      {
        Id: "6",
        Name: "AI Report",
        Url: "/reports/ai-report",
        tags: ["global"],
      },
    ].filter((r) => r.tags.includes(project ? "project" : "global"));

    setData(data);
    console.log(data);

    setLoading(false);
  }, [project]);

  return { data, loading, error };
}
