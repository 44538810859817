import Config from "../../../configurations/config";
import axios from "axios";

export function GetUsers(identity) {
  const apiPayload = { identity: identity };
  return Exec("users/all", apiPayload);
}

export function UserEdit(identity, model) {
  const apiPayload = { identity: identity, model: model };
  return Exec("users/edit", apiPayload);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
