import Config from "../../../configurations/config";
import axios from "axios";

export function GetMeta(metaId) {
  const requestBody = {
    Action: "iterations/get",
    Payload: { Id: metaId },
  };
  console.log(requestBody);
  return axios.post(Config.serverUrl, requestBody);
}

export function EditMeta(meta) {
  console.log(meta);
  const requestBody = {
    Action: "iterations/edit",
    Payload: { Model: meta },
  };

  return axios.post(Config.serverUrl, requestBody);
}

export function ExportMeta(metaId) {
  const requestBody = {
    Action: "iterations/export",
    Payload: { Id: metaId },
  };

  return axios.post(Config.serverUrl, requestBody);
}
