import axios from "axios";
import Config from "../../../configurations/config"; //"../../configurations/config";
import { GetIdentity } from "../../Auth/AppState";

export function GetProjectsByGroup(filters) {
  var identity = GetIdentity();
  var apiPayload = {
    Identity: identity,
    Filters: filters,
  };
  return Exec("projects/all", apiPayload);
}

export function GetProjects(filters) {
  var identity = GetIdentity();
  var apiPayload = {
    Identity: identity,
    Filters: filters,
  };
  return Exec("projects/all", apiPayload);
}

export function EditProject(project) {
  var identity = GetIdentity();
  const apiPayload = { Identity: identity, Model: project };
  return Exec("projects/edit", apiPayload);
}

export function GetProject(projectId) {
  var identity = GetIdentity();
  const apiPayload = { Identity: identity, ProjectId: projectId };
  return Exec("projects/get", apiPayload);
}

export function GetSignedUrl(fileKey) {
  const apiPayload = { FileKey: fileKey };
  return Exec("upload/signedurl", apiPayload);
}

export function GetSales() {
  var timeframe = "weekly";
  var url =
    "https://s3.us-west-2.amazonaws.com/app.getproject/reports/sales/sales-weekly.json";
  console.log("url: " + url);
  return axios.get(url);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
