import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useState, useEffect } from "react";

import NotFoundView from "./Components/NotFoundView";
import UserRow from "./Components/UserRow";
import useUserList from "./Hooks/useUserList";
import Spinner from "../../components/Core/DottedSpinner/DottedSpinner";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

export default function UserList() {
  const { data, setData, loading, error } = useUserList();

  return (
    <div className="m-5 w-full">
      <a
        type="button"
        href={`/users/edit`}
        className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        Add User
      </a>

      {loading ? (
        <LoadingControl />
      ) : (
        <div className="overflow-hidden bg-white shadow">
          <ul role="list" className="divide-y divide-gray-200">
            {!data ? (
              <NotFoundView />
            ) : (
              data?.map((item) => <UserRow key={item.Id} item={item} />)
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
