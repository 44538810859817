import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";

import { GetIdentity } from "../../../components/Auth/AppState";
import { UserEdit } from "../Api/UserClient";
import { useNavigate } from "react-router-dom";

export default function useUserEdit(formValues) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userEdit = useCallback(async () => {
    try {
      setLoading(true);

      var identity = GetIdentity();
      var model = {
        Name: formValues.name,
        Email: formValues.email,
        Password: formValues.password,
      };

      const response = await UserEdit(identity, model);
      const operationResponse = response.data;
      if (!operationResponse.Success) {
        toast.error(operationResponse.Message);
        return;
      }
      toast.success("Saved");

      navigate(`/users`);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues, navigate]);

  return { userEdit, loading };
}
