import * as React from "react";
import useFunnelList from "../../../FunnelApp/Hooks/useFunnelList";
import { useCallback, useState, useMatch, useEffect } from "react";
import FunnelTable from "../../../FunnelApp/Components/FunnelTable";

export default function FunnelSelectorView({ funnelId, projectId, store }) {
  const { list, loading } = useFunnelList(projectId);
  const [funnel, setFunnel] = useState(null);

  useEffect(() => {
    if (funnelId && list && list.length > 0) {
      var found = list.find((x) => x.Id === funnelId);
      setFunnel(found);
      console.log("set funnel");
    }
  }, [list, funnelId]);

  var handleChange = (e) => {
    // ===
    var funnelId = e.target.value;
    var foundItem = list.find((z) => z.Id === funnelId);
    setFunnel(foundItem);
    store.Update("FunnelId", foundItem.Id);
  };

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="funnel"
        className="block text-sm/6 font-medium text-gray-900"
      >
        Funnel
      </label>

      <div className="mt-2 grid grid-cols-1">
        {!loading ? (
          <div>
            <select
              id="funnel"
              name="funnel"
              onChange={handleChange}
              defaultValue={funnelId}
              autoComplete="funnel-name"
              className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            >
              <option id="none">None</option>
              {list.map((f) => (
                <option key={f.Id} value={f.Id}>
                  {f.Name}
                </option>
              ))}
            </select>
            <FunnelTable meta={funnel} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
