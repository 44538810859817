import * as React from "react";

import { useCallback, useState, useEffect } from "react";

import useGetData from "./Hooks/useGetData";
import TableView from "../../Components/TableView";

import ReactHtmlParser from "react-html-parser";
import LoadingControl from "../../../../components/Core/LoadingControl/LoadingControl";

export default function AIReport() {
  const { data, loading } = useGetData();

  console.log(data);

  return (
    <div className="m-5">
      <div>
        <h3>Product AI Report</h3>
        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <div>{ReactHtmlParser(data)}</div>
          </>
        )}
      </div>
    </div>
  );
}
