import * as React from "react";

export default function SalesView({ data, settings }) {
  var columns = Object.getOwnPropertyNames(data);
  columns = columns.filter(
    (col) => !col.includes("year") && !col.includes("week")
  );
  var rows = Object.getOwnPropertyNames(data[columns[0]]);

  //filter
  columns = columns.filter((col) => {
    return settings.Filters.length > 0
      ? settings.Filters.find((f) => col.startsWith(f))
      : true;
  });

  const getCost = (name, quantity) => {
    var product = settings.Products.find(
      (p) => p.AppKey + "-" + p.Platform === name
    );
    var productPrice =
      product && product.Price > 0
        ? parseFloat(product.Price)
        : parseFloat(0.0);
    return (quantity * productPrice).toFixed(2);
  };

  function getTotal(row, columns) {
    var totalSoldQuantity = 0;

    var array = columns
      .filter((d) => data[d][row] !== 0)
      .map((appKey) => {
        var soldQuantity = data[appKey][row];
        totalSoldQuantity += soldQuantity;

        return getCost(appKey, soldQuantity);
      });

    //----
    var total = 0.0;
    array.forEach((element) => {
      total += parseFloat(element);
    });

    //-----

    return "Quantity: " + totalSoldQuantity + " Total: " + total.toFixed(2);
  }

  return (
    <div>
      {rows.map((row) => (
        <p className="my-7 mx-5">
          <b>Week - {data["week"][row]}</b>
          <br />
          {settings.Groups.map((group) => {
            return (
              <>
                <p className="underline text-sm">{group.Name}</p>
                {columns
                  .filter((d) => data[d][row] !== 0)
                  .map((column) => {
                    var productKey = column;
                    if (
                      !group.AppKeys.find((appKey) =>
                        productKey.includes(appKey)
                      )
                    )
                      return <></>;

                    var soldQuantity = data[column][row];
                    return (
                      <>
                        <span className="text-sm">
                          {column} {data[column][row]}(
                          {getCost(productKey, soldQuantity)})
                        </span>
                        <br />
                      </>
                    );
                  })}
              </>
            );
          })}

          <p className="underline text-sm">Other</p>
          {columns
            .filter((d) => data[d][row] !== 0)
            .map((column) => {
              var productKey = column;

              var existInCategory = settings.Groups.find((group) =>
                group.AppKeys.find((appKey) => productKey.includes(appKey))
              );
              if (existInCategory) return <></>;

              var soldQuantity = data[column][row];
              return (
                <>
                  <span className="text-sm">
                    {column} {data[column][row]}(
                    {getCost(productKey, soldQuantity)})
                  </span>
                  <br />
                </>
              );
            })}
          {<p className="text-sm font-semibold">{getTotal(row, columns)}</p>}
        </p>
      ))}
    </div>
  );
}
