import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";

export default function TagView({ tags, setTags }) {
  const inputRef = React.createRef();
  const [chipData, setChipData] = React.useState(tags ?? []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      //====
      const trimmedText = e.target.value.trim();

      e.target.value = "";

      //inputRef.current.value = "";

      if (trimmedText.length === 0) return;

      var foundItems = chipData.filter((chip) => chip === trimmedText);
      if (foundItems.length > 0) return;

      var newChips = [...chipData, trimmedText];

      setChipData(newChips);
      setTags && setTags(newChips);
    }
  };

  const handleDelete = (itemName) => () => {
    var newChips = chipData.filter((chip) => chip !== itemName);
    setChipData(newChips);
    setTags && setTags(newChips);
  };

  return (
    <div>
      <input
        type="text"
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Tag Name"
        required
      />

      <ul className="flex">
        {chipData.map((item) => {
          return (
            <li className="m-1" key={item}>
              <Chip label={item} onDelete={handleDelete(item)} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
