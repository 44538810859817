import * as React from "react";

import Stack from "@mui/material/Stack";

import { useCallback, useState, useEffect } from "react";
import { GetProjectsByGroup } from "./Api/ResourceGroupClient";
import { useParams } from "react-router-dom";

import ProjectRow from "./Components/ProjectRow";

import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingControl from "../Core/LoadingControl/LoadingControl";

import useSalesGet from "./../Project/Hooks/useSalesGet";
import { GetIdentity } from "../Auth/AppState";

const sortArrayOfObjects = (arr, propertyName, order = "ascending") => {
  const sortedArr = arr.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  if (order === "descending") {
    return sortedArr.reverse();
  }

  return sortedArr;
};

export default function GroupProjectList() {
  const { data, downloading } = useSalesGet();
  const { groupId } = useParams();

  //==================

  const [status, setStatus] = useState(0);
  const [text, setText] = useState("");

  //==================
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    //var filters = groupId !== "" ? [{ Name: "groupId", Value: groupId }] : [];
    var identity = GetIdentity();
    var filters = [];
    filters.push({ Name: "groupId", Value: groupId });
    filters.push({ Name: "userId", Value: identity?.UserId });

    GetProjectsByGroup(filters).then((response) => {
      console.log(response.data);
      const items = sortArrayOfObjects(response.data, "Sales", "descending");
      setProjects(items);
      setLoading(false);
    });
  }, [groupId]);

  var timeout = null;
  const handleOnChange = (e) => {
    const searchValue = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setText(searchValue);
      console.log(searchValue);
      timeout = null;
    }, 500);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setStatus(e.target.value);
  };

  return (
    <div className="m-5 w-full">
      <div>
        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <Stack direction="row">
              <TextField
                id="search"
                name="search"
                label="Search"
                onChange={handleOnChange}
                fullWidth
              />

              <Select
                style={{ marginLeft: "10px", width: "170px" }}
                id="status"
                name="status"
                defaultValue={status}
                onChange={handleChange}
                fullWidth
                label="Status"
              >
                <MenuItem value={0}>Open</MenuItem>
                <MenuItem value={1}>Closed</MenuItem>
              </Select>
            </Stack>

            <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
              <ul class="flex flex-col divide-y w-full">
                {projects
                  .filter((p) => p.Status === status)
                  .filter((p) =>
                    text.length > 0
                      ? p.Name.toLowerCase().includes(text.toLowerCase())
                      : true
                  )
                  .map((project) => (
                    <ProjectRow
                      project={project}
                      key={project.Id}
                      logs={data}
                    />
                  ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
