import * as React from "react";

import { useCallback, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import ItemView from "./Components/ItemView";
import useTicketList from "./Hooks/useTicketList";

export default function TicketListV2() {
  const location = useLocation();
  var template =
    location.pathname.indexOf("project/") > 0
      ? "/project/:projectId/:appId"
      : "/helpdeskv2";
  const match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;

  const { data, loading } = useTicketList(projectId);

  console.log(data);

  const [appName, setAppName] = useState(null);
  const appNames = data
    .map((row) => row["AppKey"])
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  const selectAppName = (event) => {
    setAppName(event.target.value);
  };

  const list = data.filter((item) =>
    appName === null ? true : item.AppKey === appName
  );

  return (
    <div className="m-5 max-w-xl">
      <div>
        {loading ? (
          <LoadingControl />
        ) : (
          <>
            <label
              for="names"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select an app
            </label>
            <select
              value={appName}
              onChange={selectAppName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
              p-2.5 dark:bg-gray-700 dark:border-gray-600
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
              dark:focus:border-blue-500"
            >
              <option selected value="all">
                all apps
              </option>
              {appNames.map((name) => (
                <option value={name}>{name}</option>
              ))}
            </select>

            <div>Tickets - {list.length}</div>
            <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
              <ul class="flex flex-col divide-y w-full">
                {list.map((ticket) => (
                  <ItemView ticket={ticket} key={ticket.Id} />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
