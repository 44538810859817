import { v4 as uuid } from "uuid";

// status: NotStarted, Active, Pending, Closed
const DefaultState = {
  Id: null,
  MetaId: null,
  ProjectId: null,
  FunnelId: null,
  Status: 0, // NotStarted
  Type: 0, // Revenue
  Name: "Initial Iteration",
  Tasks: [
    {
      Id: uuid(),
      Name: "",
      Completed: false,
      Hours: 0,
      Cost: 0,
      RecordState: 0,
    },
    {
      Id: uuid(),
      Name: "",
      Completed: false,
      Hours: 0,
      Cost: 0,
      RecordState: 0,
    },
    {
      Id: uuid(),
      Name: "",
      Completed: false,
      Hours: 0,
      Cost: 0,
      RecordState: 0,
    },
  ],
};

export default DefaultState;
