import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";

export default function useGetData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    var url =
      "https://s3.us-west-2.amazonaws.com/app.getproject/reports/product-ai-weekly.html";
    axios.get(url).then((response) => {
      setData(response.data);
      console.log(response.data);
      setLoading(false);
    });
  }, []);

  return { data, loading, error };
}
