import { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetUser } from "../../AppState";

import axios from "axios";

import { ReactSession } from "react-client-session";

export default function useLogin(formValues) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async () => {
    try {
      setLoading(true);

      // POST request using axios with async/await
      const serverUrl =
        "https://a4mxhbnqhhb34fhqmna7d3ufre0gqwjn.lambda-url.us-west-2.on.aws";
      const requestBody = {
        Action: "auth/login",
        Payload: { Email: formValues.email, Password: formValues.password },
      };
      const response = await axios.post(serverUrl, requestBody);
      const operationResponse = response.data;
      if (!operationResponse.Success) {
        toast.error(operationResponse.Message);
        return;
      }

      console.log(response);

      const currentUser = operationResponse.User;
      SetUser(currentUser);

      //dispatch(setCurrentUser(currentUser));
      ReactSession.set("user", JSON.stringify(currentUser));

      navigate("/projects");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues, navigate, dispatch]);

  return { login, loading };
}
