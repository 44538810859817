import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";

import useUserList from "../../../../apps/UsersApp/Hooks/useUserList";

export default function UsersView({ users, setUsers }) {
  const { data, setData, loading, error } = useUserList();

  const inputRef = React.createRef();
  const [chipData, setChipData] = React.useState(users ?? []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      //====
      const trimmedText = e.target.value.trim();

      e.target.value = "";

      if (trimmedText.length === 0) return;

      var foundItems = chipData.filter((chip) => chip === trimmedText);
      if (foundItems.length > 0) return;

      var newChips = [...chipData, trimmedText];

      setChipData(newChips);
      setUsers && setUsers(newChips);
    }
  };

  const handleDelete = (item) => () => {
    var newChips = chipData.filter((chip) => chip.Id !== item.Id);
    setChipData(newChips);
    setUsers && setUsers(newChips);
  };

  const onSelectChange = (e) => {
    var userId = e.target.value;
    e.target.value = "";
    //----
    if (!userId || userId === "") return;
    //----
    var foundUser = data.find((u) => u.Id === userId);
    if (!foundUser) return;

    var foundItems = chipData.filter((chip) => chip.Id === userId);
    if (foundItems.length > 0) return;

    console.log(foundUser);

    var newChips = [
      ...chipData,
      { Id: userId, Name: foundUser.Name, Groups: [] },
    ];

    setChipData(newChips);
    setUsers && setUsers(newChips);
    //console.log(user);

    //var newChips = chipData.filter((chip) => chip !== itemName);
    //setChipData(newChips);
    //
  };

  return (
    <div>
      <select
        id="users"
        name="users"
        value={null}
        onChange={onSelectChange}
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        <option value={null}></option>
        {data.map((u) => (
          <option value={u.Id}>{u.Name}</option>
        ))}
      </select>

      <ul className="flex">
        {chipData.map((item) => {
          return (
            <li className="m-1" key={item}>
              <Chip label={item.Name} onDelete={handleDelete(item)} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
