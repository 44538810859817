import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetOne } from "../Api/PayWallClient";
import { colors } from "@mui/material";

export default function usePayWallOne(payWallId) {
  const [payWall, setPayWall] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    if (!payWallId) {
      setPayWall({
        id: null,
        text: "Pay Wall",
        version: "",
        features: [],
        products: [],
        button: { text: "Activate", color: "#00944c" },
      });
      setLoading(false);
      return;
    }

    GetOne(payWallId).then((response) => {
      setPayWall(response.data);
      console.log(response.data);
      setLoading(false);
    });
  }, [payWallId]);

  return { payWall, setPayWall, loading, error };
}
