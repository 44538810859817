import React, { useState } from "react";

export default function EditableList({ items, setItems }) {
  //const [items, setItems] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newItem, setNewItem] = useState({
    type: "item",
    text: "",
    platform: "all",
    question: "",
    answer: "",
  });

  const handleAddItem = () => {
    setItems([
      ...items,
      { type: "item", text: "", platform: "all", question: "", answer: "" },
    ]);
  };

  const handleSaveItem = () => {
    const updatedItems = [...items];
    updatedItems[currentItem.index] = {
      type: currentItem.type,
      text: currentItem.text,
      question: currentItem.question,
      answer: currentItem.answer,
      platform: currentItem.platform,
    };
    setItems(updatedItems);
    setIsPopupOpen(false);
  };

  const handleDeleteItem = () => {
    // eslint-disable-next-line no-restricted-globals
    var confirmed = confirm("Confirm to delete?");
    if (!confirmed) return;

    setItems(items.filter((_, i) => i !== currentItem.index));
    setIsPopupOpen(false);
  };

  const handleItemClick = (index) => {
    setCurrentItem({ ...items[index], index });
    setIsPopupOpen(true);
  };

  return (
    <div class="p-6 max-w-xl mx-auto bg-gray-100 rounded shadow-md">
      <h1 class="text-xl font-bold mb-4">Editable Q&A List</h1>

      <ul class="space-y-4">
        {items.map((item, index) => (
          <li
            key={index}
            class="p-4 bg-white cursor-pointer rounded shadow flex justify-between items-center"
            onClick={() => handleItemClick(index)}
          >
            <div>
              <p class="font-semibold">Q: {item.question}</p>
              <p>Platform: {item.platform}</p>
            </div>
            <div class="space-x-2"></div>
          </li>
        ))}
      </ul>

      <button
        class="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        onClick={handleAddItem}
      >
        Add Item
      </button>

      {isPopupOpen && (
        <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div class="bg-white p-6 rounded shadow-md w-full  max-w-2xl">
            <h2 class="text-lg font-bold mb-4">Edit Item</h2>

            <label class="block mb-2">
              <span class="text-sm font-semibold">Question</span>
              <input
                type="text"
                class="mt-1 block w-full p-2 border rounded"
                value={currentItem.question}
                onInput={(e) =>
                  setCurrentItem({ ...currentItem, question: e.target.value })
                }
              />
            </label>

            <label class="block mb-4">
              <span class="text-sm font-semibold">Answer</span>
              <textarea
                class="mt-1 h-[200px] block w-full p-2 border rounded"
                value={currentItem.answer}
                onInput={(e) =>
                  setCurrentItem({ ...currentItem, answer: e.target.value })
                }
              ></textarea>
            </label>

            <label class="block mb-4">
              <span class="text-sm font-semibold">Platform</span>
              <select
                id="archived"
                name="archived"
                defaultValue={currentItem.platform}
                onChange={(e) => {
                  console.log(currentItem);
                  setCurrentItem({ ...currentItem, platform: e.target.value });
                }}
                label="Archived"
                class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="all">All</option>
                <option value="ios">iOS</option>
                <option value="droid">Droid</option>
              </select>
            </label>

            <div class="flex justify-end space-x-2">
              <button
                class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => setIsPopupOpen(false)}
              >
                Cancel
              </button>
              <button
                class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={handleDeleteItem}
              >
                Delete
              </button>
              <button
                class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSaveItem}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
