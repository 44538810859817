import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetGuideOne } from "../Api/HelpGuideClient";

export default function useGuideGet(guideId) {
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    if (!guideId) {
      setGuide({
        Id: null,
        Text: "DEMO",
        Items: [],
        Archived: false,
      });
      setLoading(false);
      return;
    }

    GetGuideOne(guideId).then((response) => {
      setGuide(response.data);
      console.log(response.data);
      setLoading(false);
    });
  }, [guideId]);

  return { guide, setGuide, loading, error };
}
